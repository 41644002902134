
















































































































import { type PropType, computed, defineComponent, watch } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import ErrorAlert from "@/components/ErrorAlert.vue";
import { buttonVariants } from "@/lib/pfg/components/button";
import Badge from "@/lib/pfg/vue2/badge/Badge.vue";
import Card from "@/lib/pfg/vue2/card/Card.vue";
import CardContent from "@/lib/pfg/vue2/card/CardContent.vue";
import CardHeader from "@/lib/pfg/vue2/card/CardHeader.vue";
import CardTitle from "@/lib/pfg/vue2/card/CardTitle.vue";
import Input from "@/lib/pfg/vue2/input/Input.vue";
import Label from "@/lib/pfg/vue2/label/Label.vue";
import Skeleton from "@/lib/pfg/vue2/skeleton/Skeleton.vue";
import Textarea from "@/lib/pfg/vue2/textarea/Textarea.vue";
import { queries } from "@/lib/query/queries";
import type { RouteProps } from "@/router/types";
import { formatDate } from "@/utils/date";

type Props = RouteProps<"Member message">;

export default defineComponent({
	components: {
		ErrorAlert,
		Badge,
		Input,
		Card,
		CardHeader,
		CardTitle,
		CardContent,
		Label,
		Textarea,
		Skeleton,
	},
	props: {
		memberId: {
			type: Number as PropType<Props["memberId"]>,
			required: true,
		},
		messageId: {
			type: Number as PropType<Props["messageId"]>,
			required: true,
		},
	},
	setup(props, { root }) {
		const {
			status,
			data: notifications,
			error,
		} = useQuery(queries.member(props.memberId).notification.scheduled());

		const data = computed(() =>
			notifications.value?.data.find(
				(scheduledNotification) => scheduledNotification.id === props.messageId,
			),
		);

		watch(status, (value) => {
			if (value !== "success" || !!data) {
				return;
			}

			root.$router.replace({ name: "Member messages" });
		});

		return {
			status,
			data,
			error,
			formatDate,
			buttonVariants,
		};
	},
});
